import * as React from "react"

import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Banner from "../components/Clone/Banner"
import Whatis from "../components/Clone/Whatis"
import HowOur from "../components/Clone/HowOur"
import TopFeatures from "../components/Clone/TopFeatures"
import CoreFeatures from "../components/Clone/CoreFeatures"
import SecurityofOur from "../components/Clone/SecurityofOur"
import TechStack from "../components/Clone/TechStack"
import FaqSection from "../components/Clone/FaqSection"
import WhyShould from "../components/Clone/WhyShould"
import DevApproach from "../components/Clone/DevApproach"
import WhyChoose from "../components/Clone/WhyChoose"
import Addon from "../components/Clone/Addon"
import Dex from "../components/Clone/Dex"
import OurAndroid from "../components/Clone/OurAndroid"
import Testimonial from "../components/Clone/Testimonial"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import Whatisbinance from "../components/Clone/Whatisbox"
import CaseStudy from "../components/Clone/CaseStudy"




const Clone = () => (
  <section className="landing clone">
    <Layout>
      <Helmet>
      <meta name="robots" content="index, follow" />
      <title>Binance Clone Script to Create a Crypto Exchange</title>
      <meta name="description" content="Coinsclone offers Binance clone script that is 100% ready-made, bug-free software and replicates all the features and plug-ins of Binance." />
      <meta property="keywords" content="binance clone script" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Binance Clone Script to Create a Crypto Exchange" />
      <meta property="og:description" content="Coinsclone offers Binance clone script that is 100% ready-made, bug-free software and replicates all the features and plug-ins of Binance." />
      <meta property="og:url" content="https://www.coinsclone.com/binance-clone-script/" />
      <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/binance-clone-script.png" />
      <meta property="og:site_name" content="Coinsclone" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@Coinsclone" />
      <meta name="twitter:creator" content="@Coinsclone" />
      <meta name="twitter:description" content="Coinsclone offers Binance clone script that is 100% ready-made, bug-free software and replicates all the features and plug-ins of Binance." />
      <meta name="twitter:title" content="Binance Clone Script to Create a Crypto Exchange" />  
      <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/binance-clone-script.png" />
      <link rel="canonical" href="https://www.coinsclone.com/binance-clone-script/" />
      </Helmet>
      <Banner />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | Binance Clone Script </span>
        </div>
      </div>
      <Whatisbinance />
      <CaseStudy />
      {/* <QuickviewSection /> */}
      <HowOur />
      <Whatis />
      <TopFeatures />
      <CoreFeatures />
      <SecurityofOur />
      <Addon />
      <Dex />
      <OurAndroid />
      <WhyShould />
      <WhyChoose />
      <DevApproach />
      <Testimonial />
      <TechStack />
      <FaqSection />
      <UserFriendly />
    </Layout>
  </section>
)

export default Clone
 