import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



const DevApproach = () => {


  return (
    <section className="devapproach pt-100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            
            <h3 className="heading-h2"><span className="heading-h3"> Our Binance Clone Script</span>
            <span className="bluecolor">Development Approach</span> </h3>
            <p className="pharagraph head">We follow a systematic and streamlined approach for Binance clone app development.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 text-center order1" >
          <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance/planning-and-requirment-gathering.png"
                  alt="Planning"
                  width={384}
                />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h4 >1.Requirement Gathering</h4>
            <p className="pharagraph" >First, we analyze our clientele’s business needs, requests, and we conduct crypto research to validate your idea based on the possibilities of product development.
            </p>
            <br />
            <h4 >2.Planning </h4>
            <p className="pharagraph" >Our business experts will plan and provide the finest solution depending on the client’s business concept.
            </p>
          </div>
        </div>
        <div className="row table-content orderflex">
          <div className="col-lg-6 col-md-12 padr40 order2">
            <h4 >3.Designing</h4>
            <p className="pharagraph" >Our experienced blockchain developers are specialized in exceptional UI/UX design. Thus, we develop our modernistic app based on the latest trend.</p>
            <br />
            <h4 >4.Development</h4>
            <p  className="pharagraph">We carefully take the analyzed business requirements and get them into action through our top-notch Binance clone app development process.
            </p>
          </div>
          <div className="col-lg-6 col-md-12 text-center order1">
          <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance/design-development.png"
                  alt="Designing and Development"
                  width={384}
                /></div>
        </div>
        <div className="row table-content orderflex mb-25">
          <div className="col-lg-6 col-md-12 text-center order1" >
          <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance/testing-and-deployment.png"
                  alt="Testing and Deployment"
                  width={384}
                />
          </div>
          <div className="col-lg-6 col-md-12 padl60 order2">
            <h4 >5.Testing</h4>
            <p  className="pharagraph">After a successful development process, we test our pre-made Binance clone app for any defects in its functioning. If we face any errors, bugs, or issues while testing, then we rectify them quickly and undergo the deployment process.
            </p>
            <br />
            <h4 >6.Deployment</h4>
            <p className="pharagraph mb-0">Our team experts will guide you to deploy the fully functional Binance exchange clone in the server as per the client’s request.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DevApproach