import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image'



const HowOur = () => {



  return (
    <section className="how-our mb-0">
      <div className="container">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
          
          <h2 className="heading-h2"><span className="heading-h3 bluecolor">How our</span>
            Binance Clone Script works?</h2>
        </div>
        <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance-new/how-our-binance-clone-script-work.webp"
                  alt="Working flow of our Binance clone software"
                  width={1000}
                />
        <div className="text-center mt-25">
          <ButtonComponent/>
        </div>
      </div>
    </section>
  )
}

export default HowOur