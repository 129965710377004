import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Why Choose</span>
                Coinsclone For Binance Clone Script?</h2>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 left-side" >
            <StaticImage
                  src="https://coinsclone.mo.cloudinary.net/images/binance/why-choose-coinsclone-for-binance-clone.png"
                  alt="Why Choose Coinsclone For Binance Clone Script Image1"
                  width={457}
                />
            </div>
            <div className="col-lg-7 col-md-12">
              <p className="pharagraph">Coinsclone is a renowned <span className='learn-more'><a href='https://www.coinsclone.com/cryptocurrency-exchange-development-company/'>cryptocurrency exchange development company</a></span> in the crypto industry. We are experts in offering all types of crypto exchange solutions with utmost perfection at the best price in the market. Besides, we are experts in providing a Binance-like crypto exchange platform with advanced features. We helped many young crypto startups and entrepreneurs to create and launch a function-rich trading platform using our highly effective solutions. We have a pool of well-skilled developers who can handle any complex crypto project. Our developers will help to make your dream business a reality. We provide state-of-the-art solutions for all startups and entrepreneurs to initiate a stunning crypto exchange.
              </p>
              <p className="pharagraph mb-0">Our premium ultra-modern Binance-like exchange development solution is highly customizable and bug-free. Therefore, you can add or remove any trading features, themes, colors, designs, and other plug-ins according to the business requirements. So far we have delivered 100+ projects to our international clients across the world. We have an expert team to offer productive 24/7 customer and technical support to our global clients during any technical interruptions. If you have any technical queries regarding the development process, we are always here to assist you and answer queries!
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose